import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { FaTools } from 'react-icons/fa';

const AboutPage = () => {
	return (
		<Layout>
			<SEO keywords={[ `Website Development`, `Website Design`, `Web Application`, `Data Analytics`, `Data Visualization`, `Freelance`, `Colin Willer` ]} title="Services" />
			<section className="lg:mb-12">
				<h1 className="font-bold text-gray-900 text-center my-2 text-2xl lg:text-3xl xl:text-5xl xl:my-4">
					Premium Services Offered to Clients
				</h1>
				<p className="mt-4 mb-16 max-w-7xl text-base leading-7 text-gray-900 text-center md:text-2xl lg:mx-auto">
					Read more about the variety of services offered by Willer Technologies to see how they can help your
					business
				</p>

				{/* Website Design and Development */}
				<div className="mb-12 md:py-12 md:my-12">
					<div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
						<div className="text-center">
							<h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-wtblue sm:text-4xl sm:leading-10">
								Website Design and Development
							</h2>
							<p className="mt-4 max-w-6xl text-base leading-7 text-gray-900 md:text-xl lg:mx-auto">
								Looking to build a website for your business but don’t know where to start? Or maybe you
								have a website but want to rebrand your site to give it a fresh new look? Let me help
								you improve your online presence so you can shine in today’s digital environment. I
								design and develop blazing fast websites customized specifically for each client to
								increase your online exposure and boost sales.
							</p>
						</div>

						<div className="mt-10">
							<ul className="md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
								<li className="mb-4 md:mb-0">
									<div className="flex">
										<div className="flex-shrink-0">
											<div className="flex items-center justify-center h-12 w-12 rounded-md bg-wtblue text-white">
												{/* Heroicon 2px stroke weight, 24x24 bounding box - 'code' */}
												<svg
													className="h-6 w-6"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
													/>
												</svg>
											</div>
										</div>
										<div className="ml-4">
											<h4 className="text-lg leading-6 font-medium text-gray-900">
												Website Development
											</h4>
											<p className="mt-2 text-base leading-6 text-gray-900">
												I develop custom websites for all my clients specifically to fit each
												company’s unique branding message. I utilize the latest technologies
												available to provide websites that perform well and allow you to update
												your content on your own without touching a single line of code.
											</p>
										</div>
									</div>
								</li>
								<li className="mb-4 md:mb-0">
									<div className="flex">
										<div className="flex-shrink-0">
											<div className="flex items-center justify-center h-12 w-12 rounded-md bg-wtblue text-white">
												{/* Heroicon 2px stroke weight, 24x24 bounding box - 'search' */}
												<svg
													className="h-6 w-6"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
													/>
												</svg>
											</div>
										</div>
										<div className="ml-4">
											<h4 className="text-lg leading-6 font-medium text-gray-900">
												Search Engine Optimization (SEO)
											</h4>
											<p className="mt-2 text-base leading-6 text-gray-900">
												Want to make sure people can find your site online? I build all of my
												websites with SEO in mind to help you maximize your exposure to
												potential clients.
											</p>
										</div>
									</div>
								</li>
								<li className="mb-4 md:mb-0">
									<div className="flex">
										<div className="flex-shrink-0">
											<div className="flex items-center justify-center h-12 w-12 rounded-md bg-wtblue text-white">
												<p className="block text-xl text-center text-white">
													<FaTools style={{ display: 'inline-block' }} />
												</p>
											</div>
										</div>
										<div className="ml-4">
											<h4 className="text-lg leading-6 font-medium text-gray-900">
												Maintenance and Content Management
											</h4>
											<p className="mt-2 text-base leading-6 text-gray-900">
												Don’t have time to make sure your website is up to date or update your
												site’s content on a regular basis? Let me take care of this hassle for
												you for you so you can focus on building your business.
											</p>
										</div>
									</div>
								</li>
								<li className="mb-4 md:mb-0">
									<div className="flex">
										<div className="flex-shrink-0">
											<div className="flex items-center justify-center h-12 w-12 rounded-md bg-wtblue text-white">
												{/* Heroicon 2px stroke weight, 24x24 bounding box - 'shopping-cart' */}
												<svg
													className="h-6 w-6"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
													/>
												</svg>
											</div>
										</div>
										<div className="ml-4">
											<h4 className="text-lg leading-6 font-medium text-gray-900">E-Commerce</h4>
											<p className="mt-2 text-base leading-6 text-gray-900">
												Want to start selling your products or services online? Let me help you
												build a website with online shopping functionality to add a digital
												sales channel for your business.
											</p>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>

				{/* Business Applications and Data Analytics Section */}
				<div className="mb-12 md:py-12 md:my-12">
					<div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
						<div className="text-center">
							<h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-wtblue sm:text-4xl sm:leading-10">
								Business Applications and Data Analytics
							</h2>
							<p className="mt-4 max-w-6xl text-base leading-7 text-gray-900 md:text-xl lg:mx-auto">
								Do you have an old-fashioned, mundane process that you’ve always wished could be
								digitized to make your life easier? Or does your company have a massive amount of data
								that you can’t seem to leverage to make smarter business decisions? I have previous
								experience in building web applications for businesses, visualizing data to help paint
								the true picture, and leveraging big data to come up with business solutions that
								improve their operations. Let’s work together to come up with a solution to help improve
								your business.
							</p>
						</div>

						<div className="mt-10">
							<ul className="md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
								<li className="mb-4 md:mb-0">
									<div className="flex">
										<div className="flex-shrink-0">
											<div className="flex items-center justify-center h-12 w-12 rounded-md bg-wtblue text-white">
												{/* Heroicon 2px stroke weight, 24x24 bounding box - 'desktop-computer' */}
												<svg
													className="h-6 w-6"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
													/>
												</svg>
											</div>
										</div>
										<div className="ml-4">
											<h4 className="text-lg leading-6 font-medium text-gray-900">
												Web Applications
											</h4>
											<p className="mt-2 text-base leading-6 text-gray-900">
												I help businesses get rid of their paper and pencil processes by
												replacing them with robust web applications. Not only will this help
												make everyone’s life a little easier at your organization, it will also
												help you capture data you may not have had access to before.
											</p>
										</div>
									</div>
								</li>
								<li className="mb-4 md:mb-0">
									<div className="flex">
										<div className="flex-shrink-0">
											<div className="flex items-center justify-center h-12 w-12 rounded-md bg-wtblue text-white">
												{/* Heroicon 2px stroke weight, 24x24 bounding box - 'presentation-chart-line' */}
												<svg
													className="h-6 w-6"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
													/>
												</svg>
											</div>
										</div>
										<div className="ml-4">
											<h4 className="text-lg leading-6 font-medium text-gray-900">
												Data Visualization
											</h4>
											<p className="mt-2 text-base leading-6 text-gray-900">
												Looking for a way to visualize your data in an organized dashboard so
												you can focus on making the right decisions with the information
												available to you? I build custom data visualizations and dashboards
												utilizing a variety of technologies including Microsoft Power BI,
												Tableau, or even Microsoft Excel.
											</p>
										</div>
									</div>
								</li>
								<li className="mb-4 md:mb-0">
									<div className="flex">
										<div className="flex-shrink-0">
											<div className="flex items-center justify-center h-12 w-12 rounded-md bg-wtblue text-white">
												{/* Heroicon 2px stroke weight, 24x24 bounding box - 'filter' */}
												<svg
													className="h-6 w-6"
													fill="currentColor"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
													/>
												</svg>
											</div>
										</div>
										<div className="ml-4">
											<h4 className="text-lg leading-6 font-medium text-gray-900">
												Exploratory Data Analysis
											</h4>
											<p className="mt-2 text-base leading-6 text-gray-900">
												Looking to filter through a ton of data to help gain valuable insights?
												Let me help you explore your data with descriptive and predictive
												analytics that you can use to gather meaningful insights from the data
												you already have.
											</p>
										</div>
									</div>
								</li>
								<li className="mb-4 md:mb-0">
									<div className="flex">
										<div className="flex-shrink-0">
											<div className="flex items-center justify-center h-12 w-12 rounded-md bg-wtblue text-white">
												{/* Heroicon 2px stroke weight, 24x24 bounding box - 'document-report' */}
												<svg
													className="h-6 w-6"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
													/>
												</svg>
											</div>
										</div>
										<div className="ml-4">
											<h4 className="text-lg leading-6 font-medium text-gray-900">
												Data Driven Solutions
											</h4>
											<p className="mt-2 text-base leading-6 text-gray-900">
												Looking for a business recommendation based on a data driven decision? I
												can leverage my skills in prescriptive analytics to come up with a
												solution that helps you improve your business.
											</p>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>

				{/* A Quick Note About Pricing Section*/}
				<div className="mb-12 md:py-12 md:my-12">
					<div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
						<div className="text-center">
							<h2 className="mt-2 text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-10">
								A Quick Note About Pricing
							</h2>
							<p className="mt-4 max-w-6xl leading-7 text-gray-900 md:text-xl lg:mx-auto">
								Asking how much it costs for a website, a business application, or a data analytics
								project is a little bit like asking how much does a house cost? Without knowing the
								number of bedrooms, bathrooms, garage stalls, or how good the neighborhood is it is hard
								for me to give you an estimate on what it will take to complete your project. To learn
								more please contact me via email at info@willertechnologies.com, phone (920) 460-9757,
								or by using the{' '}
								<Link className="text-wtblue" to="/contact">
									contact
								</Link>{' '}
								form on this website.
							</p>
						</div>
					</div>
				</div>

				{/* Call to Action */}
				<div className="mb-12 md:py-12 md:my-12">
					<div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
						<div className="text-center">
							<h2 className="mt-2 text-2xl leading-8 font-extrabold tracking-tight text-gray-900 md:text-4xl sm:leading-10">
								<span className="text-wtblue">{'Ready to dive in? '}</span> <br />{' '}
								{"Let's get started today."}
							</h2>
						</div>
					</div>
					<div className="w-full mt-6">
						<div className="flex justify-center">
							<div className="md:inline-block">
								<Link
									to="/portfolio"
									className="block bg-wtblue text-center text-white text-md font-bold py-2 px-4 mx-2 mb-4 rounded w-48 transition duration-500 ease-in-out hover:bg-wtbluehover md:mb-0 md:inline-block xl:text-xl xl:w-56"
								>
									Example Projects
								</Link>
								<Link
									to="/contact"
									className="block bg-transparent border border-wtblue text-center text-wtblue text-md font-bold py-2 px-4 w-48 mx-2 rounded mt-2 xl:mt-0 lg:ml-4 transition duration-500 ease-in-out hover:bg-wtbluehover hover:text-white md:inline-block xl:text-xl xl:w-56"
								>
									Get a Free Quote
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default AboutPage;
